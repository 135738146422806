import React from "react"
import { useQuery } from "@apollo/react-hooks"
import renderHTML from "react-render-html"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import Helmet from "react-helmet"

import GET_ADRESSES from "../queries/getAdresses.graphql"

import SEO from "../components/seo"
import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"

import "../styles/global.css"

const AdressesPage = () => {
  const { loading, error, data } = useQuery(GET_ADRESSES)
  if (loading) return null
  if (error) return `Error! ${error}`

  //Deconstruct the query
  const {
    adressepageTitreTexte,
    adressepageEtablissements,
  } = data.pageBy.acfAddress

  // Map questions and responses from the query
  const adressepageEtablissementsData = adressepageEtablissements.map(item => {
    let data = {
      type: item.adressepageEtablissementsType,
      nom: item.adressepageEtablissementsNom,
      adresse: item.adressepageEtablissementsAdresse,
    }
    return data
  })

  // Create component and render etablissements
  const ListEtablissements = (ets, option) => {
    let types = new Set()
    let elements = ets.map(elem => {
      types.add(elem.type)
      return { ...elem }
    })
    //types = [...types]
    let firstType = elements.filter(item => item.type === [...types][0])
    let secondType = elements.filter(item => item.type === [...types][1])

    console.table("firstType", firstType, "secondType", secondType)

    return firstType.map((et, i) => (
      <div key={i}>
        {et.type && <p className="adressePage_type">{et.type}</p>}
        {et.nom && <p className="adressePage_nom">{et.nom}</p>}
        <div className="adressePage_adresse">
          {et.adresse && renderHTML(et.adresse)}
        </div>
      </div>
    ))

    /*if (option.lowerCase === 'right') {
            return secondType.map((et, i) => (
            <div key={i}>
              {et.type && <p className="adressePage_type">{et.type}</p>}
              {et.nom && <p className="adressePage_nom">{et.nom}</p>}
              <div className="adressePage_adresse">
                {et.adresse && renderHTML(et.adresse)}
              </div>
            </div>
        ))}*/
  }

  // render
  return (
    <>
      <SEO title="Les Adresses Utiles" keywords={[`Trouver un préservatif`]} />
      <Helmet>
        <title>Les Adresses Utiles</title>
        <meta name="description" content="Les Adresses Utiles" />
      </Helmet>
      <Container>
        <Header>
          <div className="block-header">
            <CustomMenu />
            <TopHeader />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Header>
        <Content id="page-wrap">
          <div className="content">
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                {adressepageTitreTexte &&
                  adressepageTitreTexte[0].adressepageTitre && (
                    <h2 className="adresse-titre">
                      {adressepageTitreTexte[0].adressepageTitre}
                    </h2>
                  )}
                <div className="adresse-texte">
                  {adressepageTitreTexte &&
                    adressepageTitreTexte[0].adressepageTexte &&
                    renderHTML(adressepageTitreTexte[0].adressepageTexte)}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <FlexboxGrid justify="">
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={12}
                    xs={24}
                    className="adresses_utiles_col"
                  >
                    {ListEtablissements(adressepageEtablissementsData, "left")}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={12}
                    xs={24}
                  >
                    {ListEtablissements(adressepageEtablissementsData, "right")}
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default AdressesPage
